import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { OpenDonateBox, OpenDrawer, OpenUploadBox } from '../UserRedux/Action/StateAction';
import DonateBox from './DonetForm';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Fade } from 'react-awesome-reveal'
import { FaUpload } from 'react-icons/fa';
import UploadImage from './UploadImageState';
import { Link } from 'react-router-dom';


const NavigationTop = () => {


    const navigate = useNavigate()
    const dispatch = useDispatch()


    return (
        <div>
            <div className='d-none d-md-flex' style={{ height: '1.4rem', backgroundColor: 'rgb(61, 48, 130)', width: '100%', position: 'fixed', top: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', zIndex: 1000 }} >
            </div>
            <div className='d-md-flex nav-container d-none ' style={{ paddingLeft: '3.5rem', paddingRight: '5rem', width: '100%', height: '5.2rem', background: 'white', borderRadius: '0.0003rem', justifyContent: 'center', position: 'fixed', top: '1.3rem', zIndex: 100, paddingTop: 14 }} >
                <div className='container-fluid d-flex' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

                    <div className='d-none d-md-flex' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 0 }}  >
                        <p className='logo' ><img src={require('../images/mow.png')} style={{ width: '180px', position: 'relative', top: '-0.1rem' }} /> </p>
                    </div>

                    <div className='d-flex d-md-none' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 7 }}  >
                        <p className='logo' style={{ fontSize: 18 }} ><img src={require('../images/mow.png')} style={{ width: '100px', position: 'relative', top: '-0.1rem' }} /></p>
                    </div>

                    <div className='card d-none d-lg-flex' style={{ width: '26rem', border: 'none', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }}>
                        <p onClick={() => navigate('/')} className='menu-link'>Home</p>
                        <p onClick={() => navigate('/about')} className='menu-link'>About</p>
                        <p onClick={() => navigate('/usps')} className='menu-link'>Contact</p>
                        <div className='galleryContainer' style={{ position: 'relative' }}  >
                            <a style={{ textDecoration: 'none', color: 'rgb(61, 48, 130)' }} ><p className='menu-link gallery'>Gallery</p>
                            </a>

                            <Fade>
                                <div style={{ display: 'flex', padding: 14, paddingLeft: 9, alignItems: 'center', borderRadius: '0.2rem' }} className='galleryDrop'>
                                    <div className='mt-2'>
                                        <p onClick={() => navigate('/video')} style={{ color: 'white', cursor: 'pointer' }} >Video</p>
                                        <p onClick={() => navigate('/gallery')} style={{ color: 'white', marginTop: 30, cursor: 'pointer' }} >Photo</p>
                                    </div>
                                </div>
                            </Fade>
                        </div>

                    </div>

                    <button onClick={() => dispatch(OpenUploadBox())} style={{ paddingBottom: 2.1, position: 'relative', top: '-0.4rem', border: '1.5px solid rgb(98, 171, 175)' }} className='signupButton d-flex justify-content-center align-items-center d-none d-lg-block'  >
                        <FaUpload style={{ fontSize: 14, position: 'relative', top: '-0.1rem' }} /> Upload Image
                    </button>
                    <Link to="http://localhost:3001/" className="text-decoration-none">
                    <button style={{ paddingBottom: 2.1, position: 'relative', top: '-0.4rem', border: '1.5px solid rgb(98, 171, 175)' }} className='signupButton d-flex justify-content-center align-items-center d-none d-lg-block'  >
                     Admin Login
                    </button>
                    </Link>
                  
                    <button onClick={() => dispatch(OpenDrawer())} style={{ marginTop: '-1.32rem', width: '2rem', height: '2rem', border: '1.2px solid rgb(61, 48, 130)', borderRadius: '50%', color: 'rgb(61, 48, 130)', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='d-block d-lg-none' >
                        <MenuOutlinedIcon style={{ fontSize: 18, position: 'relative', top: '-0.12rem' }} />
                    </button>
                </div>
            </div>

            <div className='d-flex d-md-none ps-2 pe-2' style={{ height: '1.4rem', backgroundColor: 'rgb(61, 48, 130)', width: '100%', position: 'fixed', top: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', paddingBottom: 7, zIndex: 1000 }} >

            </div>

            <div className='d-flex nav-container d-md-none ' style={{ paddingLeft: '0.1rem', paddingRight: '0.5rem', width: '100%', height: '5.9rem', background: 'white', borderRadius: '0.0003rem', justifyContent: 'center', position: 'fixed', top: '1.3rem', zIndex: 100, paddingTop: 14 }} >
                <div className='container-fluid d-flex' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

                    <div className='d-flex d-md-none' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >
                        <p className='logo' style={{ fontSize: 18 }} ><img src={require('../images/mow.png')} style={{ width: '210px', position: 'relative', top: '-0.1rem', marginLeft: '-1.87rem' }} /></p>
                    </div>

                    <div className='card d-none d-lg-flex' style={{ width: '26rem', border: 'none', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }}>
                        <p onClick={() => navigate('/')} className='menu-link'>Home</p>
                        <p onClick={() => navigate('/about')} className='menu-link'>About</p>
                        <a onClick={() => navigate('/gallery')} style={{ textDecoration: 'none', color: 'rgb(61, 48, 130)' }} ><p className='menu-link'>Gallery</p></a>
                        <p onClick={() => navigate('/usps')} className='menu-link'>Contact</p>
                    </div>

                    <button onClick={() => dispatch(OpenDonateBox())} style={{ paddingBottom: 2.1, position: 'relative', top: '-0.4rem', border: '1.5px solid rgb(98, 171, 175)' }} className='signupButton d-flex justify-content-center align-items-center d-none d-lg-block'  >
                        Donate
                    </button>

                    <button style={{ paddingBottom: 2.1, position: 'relative', top: '-0.4rem', border: '1.5px solid rgb(98, 171, 175)' }} className='signupButton d-flex justify-content-center align-items-center d-none d-lg-block'  >
                       Admin Login
                    </button>

                    <button onClick={() => dispatch(OpenDrawer())} style={{ marginTop: '-1.32rem', width: '2.3rem', height: '2.3rem', border: '1.2px solid rgb(61, 48, 130)', borderRadius: '50%', color: 'rgb(61, 48, 130)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: -16 }} className='d-block d-lg-none' >
                        <MenuOutlinedIcon style={{ fontSize: 18, position: 'relative', top: '-0.12rem' }} />
                    </button>
    
                </div>
            </div>
            <DonateBox />
            <UploadImage />
        </div>
    )
}

export default NavigationTop