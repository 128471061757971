import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSelector, useDispatch } from 'react-redux';
import { OpenDonateBox, OpenDrawer, OpenUploadBox } from '../../UserRedux/Action/StateAction';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CollectionsIcon from '@mui/icons-material/Collections';
import InfoIcon from '@mui/icons-material/Info';
import DonateBox from '../../Component/DonetForm';
import { FaPhotoVideo, FaUpload, FaVideo } from 'react-icons/fa';
import UploadImage from '../../Component/UploadImageState';
import { Link, useNavigate } from 'react-router-dom';

export default function SideBar() {

    const navigate = useNavigate()

    const myState = useSelector((state) => state.DrawerUpdate)
    const dispatch = useDispatch()

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [isGallerDrop, setIsGalleryDrop] = React.useState(false)

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 305 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className='mt-4 ms-2' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 7 }}  >
                <p className='logo' style={{ fontSize: 18 }} ><img src='https://s.yimg.com/fz/api/res/1.2/9cXHujfaXMy2nqlyExL2Sg--~C/YXBwaWQ9c3JjaGRkO2ZpPWZpdDtoPTI2MDtxPTgwO3c9Mjc0/https://s.yimg.com/zb/imgv1/1e7eb57b-d10c-3e65-aed4-a9b1626e594c/t_500x300' style={{ width: '30px', position: 'relative', top: '-0.1rem' }} /> <span >Lions</span>  <span style={{ color: 'rgb(61, 48, 130)' }} >meals</span>  <span style={{ color: 'rgb(63, 46, 151)' }} >on</span>  <span style={{ color: 'rgb(61, 37, 185)' }} >wheels</span></p>
            </div>
            <div style={{ width: '100%', height: '0.15rem', backgroundColor: 'rgb(61, 48, 130)' }} ></div>
            <List>
                <ListItem >
                    <ListItemButton onClick={() => {
                        dispatch(OpenDrawer())
                        navigate('/')
                    }} >
                        <ListItemIcon>
                            <HomeRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary='Home' />
                    </ListItemButton>
                </ListItem>

                <ListItem >
                    <ListItemButton onClick={() => dispatch(OpenDrawer())} >
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary='About' />
                    </ListItemButton>
                </ListItem>

                <ListItem>
                    <ListItemButton onClick={() => setIsGalleryDrop(!isGallerDrop)} >
                        <ListItemIcon>
                            <CollectionsIcon />
                        </ListItemIcon>
                        <ListItemText primary='Gallery' />
                    </ListItemButton>
                </ListItem>

                {
                    isGallerDrop && (
                        <div style={{ backgroundColor: 'rgb(35, 24, 90)', borderRadius: '0.2rem', marginLeft: 32, marginRight: 10 }} >
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    dispatch(OpenDrawer())
                                    navigate('/video')
                                    setIsGalleryDrop(false)
                                }} >
                                    <ListItemIcon>
                                        <FaVideo color='white' />
                                    </ListItemIcon>
                                    <ListItemText style={{ color: 'white' }} primary='Video' />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    dispatch(OpenDrawer())
                                    navigate('/gallery')
                                    setIsGalleryDrop(false)
                                }} >
                                    <ListItemIcon>
                                        <FaPhotoVideo color='white' />
                                    </ListItemIcon>
                                    <ListItemText style={{ color: 'white' }} primary='Photo' />
                                </ListItemButton>
                            </ListItem>
                        </div>
                    )
                }

                <ListItem >
                    <ListItemButton onClick={() => dispatch(OpenDrawer())} >
                        <ListItemIcon>
                            <LocalPhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary='Contact' />
                    </ListItemButton>
                </ListItem>


                <button onClick={() => dispatch(OpenDonateBox())} style={{ paddingBottom: 2.1, position: 'relative', top: '-0.4rem', border: '1.5px solid rgb(98, 171, 175)', width: '11rem', marginTop: 140, marginLeft: 31 }} className='signupButton d-flex justify-content-center align-items-center'  >
                    Donate
                </button>
                <button onClick={() => dispatch(OpenUploadBox())} style={{ paddingBottom: 2.1, position: 'relative', top: '-0.4rem', border: '1.5px solid rgb(98, 171, 175)', width: '11rem', marginTop: 24, marginLeft: 31 }} className='signupButton d-flex justify-content-center align-items-center'  >
                    <FaUpload style={{ fontSize: 14, position: 'relative', top: '-0.1rem', marginRight: 2 }} /> Upload Image
                </button>
                <Link to="http://localhost:3001/" className="text-decoration-none">
                <button  style={{ paddingBottom: 2.1, position: 'relative', top: '-0.4rem', border: '1.5px solid rgb(98, 171, 175)', width: '11rem', marginTop: 24, marginLeft: 31 }} className='signupButton d-flex justify-content-center align-items-center'  >
                     Admin Login
                </button>
                </Link>
            </List>
            <DonateBox />
            <UploadImage />

        </Box>
    );

    return (
        <div>

            <React.Fragment key={'left'}>
                <SwipeableDrawer
                    anchor={'left'}
                    open={myState}

                >
                    {list('left')}
                </SwipeableDrawer>
            </React.Fragment>

        </div>
    );
}