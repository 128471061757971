import * as React from 'react';
import axios from 'axios';
import AdminNavigation from '../Container/Component/AdminNavigation';
import AdminSidebar from './AdminSidebar';

function AdminHome() {


    const [images, setImages] = React.useState([])

    const imagesItem = () => {
        axios.post('https://mealsapi.emedha.in/upload/show-image').then((res) => {
            setImages(res.data)
        })
    }

    React.useEffect(() => {
        imagesItem()
    }, [])


    return (
        <div>
            <AdminNavigation />
            <AdminSidebar />
            <div style={{ marginTop: 190, padding: 20, display: 'flex', flexWrap: 'wrap' }} >
                {
                    images.map((data) => {
                        return (

                            <div style={{ width: '33.33333333333333%', borderRadius: 2, padding: 13, borderTop:'1px solid lightgray' }}>
                                <img src={data.image} className='mb-2' style={{ width: '100%' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between',marginTop:20, alignItems: 'center' }} >
                                    <button style={{ border: '2px solid green', color: 'green', fontWeight: '600', width: '31%', height: '2.3rem', borderRadius: '3rem', backgroundColor: 'white' }} >Approve</button>
                                    <button style={{ border: '2px solid gold', color: 'gold', fontWeight: '600', width: '31%', height: '2.3rem', borderRadius: '3rem', backgroundColor: 'white' }} >Update</button>
                                    <button style={{ border: '2px solid red', color: 'red', fontWeight: '600', width: '31%', height: '2.3rem', borderRadius: '3rem', backgroundColor: 'white' }}>Delete</button>
                                </div>

                            </div>

                        )
                    })
                }
            </div>
        </div>
    );
}

export default AdminHome