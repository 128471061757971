import Carousel from 'react-bootstrap/Carousel';
import { OpenDonateBox } from '../../UserRedux/Action/StateAction';
import { useDispatch } from 'react-redux';

function CarouselTop() {

    const dispatch = useDispatch()


    return (
        <Carousel indicators={false} fade style={{ marginTop: 104 }} >
            <Carousel.Item>
                <img src={require('../../images/car1.jpeg')} style={{ width: '100%' }} />
                <Carousel.Caption>
                    <h3 style={{ textShadow: '2px 3px 3px black', fontSize: 12, fontWeight: '600' }} >Donate  Now, Celebrate  Birthday or any Special Occasion of yours or your Family & Friends </h3>
                    <img onClick={() => dispatch(OpenDonateBox())} src='https://i.giphy.com/media/BNDlFWRKTb4xGt5gxE/giphy.webp' width='150px' style={{ cursor: 'pointer' }} ></img>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={require('../../images/car2.jpeg')} style={{ width: '100%' }} />
                <Carousel.Caption>
                    <h3 style={{ textShadow: '2px 3px 3px black', fontSize: 12, fontWeight: '600' }} >Donate  Now, Celebrate  Birthday or any Special Occasion of yours or your Family & Friends </h3>
                    <img src='https://i.giphy.com/media/BNDlFWRKTb4xGt5gxE/giphy.webp' width='150px' style={{ cursor: 'pointer' }} ></img>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={require('../../images/car3.jpeg')} style={{ width: '100%' }} />
                <Carousel.Caption>
                    <h3 style={{ textShadow: '2px 3px 3px black', fontSize: 12, fontWeight: '600' }} >Donate  Now, Celebrate  Birthday or any Special Occasion of yours or your Family & Friends </h3>
                    <img src='https://i.giphy.com/media/BNDlFWRKTb4xGt5gxE/giphy.webp' width='150px' style={{ cursor: 'pointer' }} ></img>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default CarouselTop;