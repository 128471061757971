import React from "react";
import { FaFacebook, FaInstagram, FaMapMarkerAlt, FaPhone, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Typography } from "@mui/material";

const Footer = () => {

    return (
        <div style={{ width: '100%', backgroundColor: 'black', marginTop: 37 }} >
            <div style={{ height: '10.5rem', width: '3rem', backgroundColor: 'white', position: 'fixed', bottom: 0, right: 5, zIndex: 1000 }}  >
                <a href="mailto:lionsmow@gmail.com" target="_blank" >
                    <div style={{ width: '4rem', backgroundColor: 'rgb(150, 59, 59)', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <MailOutlineIcon style={{ color: 'white', fontSize: 24.5 }} />
                    </div>
                </a>
                <a href="tel:+919440081628" >
                    <div style={{ width: '4rem', backgroundColor: 'rgb(10, 71, 224)', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <FaPhone style={{ fontSize: 24.5, color: 'white' }} />
                    </div>
                </a>
                <a href="https://api.whatsapp.com/send?phone=9440081628" target="_blank" >
                    <div style={{ width: '4rem', backgroundColor: 'green', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <FaWhatsapp style={{ color: 'white', fontSize: 24.5 }} />
                    </div>
                </a>
            </div>
            <div className="container" >
                <div className="row p-1 pt-5 pb-5 p-lg-5 gy-3" >
                    <div className="col-12 col-md-5 col-xl-7 mx-auto" >
                        <p style={{ fontSize: 24, fontWeight: '900', color: 'white' }} >Contact Us</p>
                        <div style={{ width: '4rem', height: '0.18rem', backgroundColor: 'white', position: 'relative', zIndex: 50 }} ></div>
                        <img src={require('../../Container/images/mow.png')} style={{ width: '120px' }} />
                        <p className="mt-3" >
                            <div className="d-flex mb-5" >
                                <FaMapMarkerAlt style={{ color: 'red', fontSize: 28, position: 'relative', top: '1rem' }} />
                                <Typography style={{ marginTop: 17, fontSize: 16.5, fontWeight: '500', marginLeft: 5, color: 'white' }} >
                                    Lions Club Bhavan & Guest House 1-8-179, Lakhpath Building, Near Paradise Circle, MG Road, PG Road, Jogani, Ramgopalpet, Secunderabad, Telangana
                                </Typography>
                            </div>

                            <p style={{ marginTop: 0, fontSize: 16.5, fontWeight: '500', marginLeft: 5, color: 'white' }} ><LocalPhoneIcon style={{ fontSize: 19, color: 'red', position: 'relative', top: '-0.1rem' }} /> +91 94400 81628</p>
                            <p style={{ fontSize: 16.5, fontWeight: '500', marginLeft: 5, color: 'white' }} ><MailOutlineIcon style={{ fontSize: 19, color: 'red', position: 'relative', top: '-0.1rem' }} /> lionsmow@gmail.com</p>
                        </p>
                    </div>
                    <div className="col-12 col-md-5 col-xl-5 mx-auto" >
                        <p style={{ fontSize: 24, fontWeight: '900', color: 'white' }} >Fallow  Us</p>
                        <div style={{ width: '4rem', height: '0.18rem', backgroundColor: 'white', position: 'relative' }} ></div>
                        <br />
                        <div className="icons">
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="#" target="_blank" ><FaFacebook className="icon" /></a>
                            </div>
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="#" target="_blank" ><FaInstagram className="icon" /></a>
                            </div>
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="#" target="_blank" ><FaTwitter className="icon" /></a>
                            </div>
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="#" target="_blank" ><FaYoutube className="icon" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12  ps-lg-4 pe-lg-4" >
                        <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="250" id="gmap_canvas" src="https://maps.google.com/maps?q=Lions%20Club%20Bhavan%20&%20Guest%20House%201-8-179,%20Lakhpath%20Building,%20Near%20Paradise%20Circle,%20MG%20Road,%20PG%20Road,%20Jogani,%20Ramgopalpet,%20Secunderabad,%20Telangana&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                    </div>
                    <div className="col-12 ps-lg-4 pe-lg-4" >
                        <div style={{ width: '100%', height: '0.12rem', backgroundColor: 'white' }} ></div>
                        <Typography style={{ textAlign: 'center', fontSize: 14, color: 'white', marginTop: 42 }} >@ Meals On wheels 2023. Allright reserved</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
