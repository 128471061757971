import React from 'react'
import SideBar from './SideBar'
import NavigationTop from '../../Component/Navigation'

export const Video = () => {
    return (
        <div style={{ marginTop: '8rem', padding: 7, }}>
            <NavigationTop />
            <SideBar />
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/jA47A8p3R44?si=SQNmg_kmgeWegwJX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/L9HYuBtt7uc?si=g7CJRZ-kFIJqEBc5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/OhMvH9U5nXE?si=vix8Xn4sb-uFKCyO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/P9uvfVCogFs?si=AdfnYMlJeupanRFl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    )
}