import './App.css';
import { Home } from './Container/WebScreen/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap'
import Footer from './Container/Component/Footer';
import Gallery from './Container/WebScreen/Home/Gallery';
import { Video } from './Container/WebScreen/Home/Video';
import AdminHome from './AdminContainer/AdminHome';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
       
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/video' element={<Video />} />
          <Route path='/admin-panel' element={<AdminHome />} >
            
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
